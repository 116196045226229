import React, { useState, useEffect, useContext } from 'react';
import MarketBar from './marketBar.js';
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography"
import { styled } from '@mui/material/styles';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';

// components
import LockArea from "./Forms/LockArea.js";
import ManageArea from "./Forms/ManageArea.js";
import ChartArea from './ChartArea.js';
import TokenStats from './TokenStats.js';

// api data
import { fetchTradesFromDatabase } from "../components/services/api.js"


// trade item
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));



export default function Core({ address }) {

  const [trades, setTrades] = useState([]); // trades data
  const [switchTabs, setSwitchTabs] = React.useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const tradesPerPage = 10;
  const indexOfLastTrade = currentPage * tradesPerPage;
  const indexOfFirstTrade = indexOfLastTrade - tradesPerPage;
  const currentTrades = trades.slice(indexOfFirstTrade, indexOfLastTrade);


  // Placeholder data and functions
  const totalPages = Math.ceil(trades.length/tradesPerPage); // Total number of pages fetchTradesFromDatabase
  let page = 1; // Current page
  const handlePageChange = (event, value) => {
  // Your function to handle page change
  // For example:
  // setPage(value);
  // 
  };

  function formatTokenAmount(amount) {
    if (amount >= 1e9) {
        return (amount / 1e9).toFixed(0) + "B";
    } else if (amount >= 500e6) {
        return "500M";
    } else if (amount >= 100e6) {
        return "100M";
    } else if (amount >= 1e6) {
        return (amount / 1e6).toFixed(0) + "M";
    } else if (amount >= 500e3) {
        return "500k";
    } else if (amount >= 100e3) {
        return "100k";
    } else {
        return amount.toString();
    }
}

  const handleSwitchTabs = (event, newValue) => {
    setSwitchTabs(newValue);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchTradesFromDatabase(address);
        setTrades(data);
      } catch (error) {
        console.error('Error fetching trades:', error);
      }
    };

    fetchData(); // Call the fetchData function
  }, []); // Run effect when address changes

  return (
    <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          paddingTop: "35px",
          flexWrap: "wrap",
        }}
      >
        
        <Grid container spacing={4} justifyContent="center">
          {/* First Box */}
          <Grid item sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Paper
                      elevation={4}
                      sx={{
                        height: '100%',
                        width: { xs: '400px', sm: '800px', }, // Adjust width as needed for different screen sizes
                        borderRadius: '16px',
                        boxShadow: 'rgba(183, 48, 255, 0.18) 0px 1px 8rem',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        textAlign: 'center',
                        
                      }}
                    >
                      {/* CHART AREA */}
                      <ChartArea address={address} />
                    </Paper>
          </Grid>

                  <Grid item sx={{ display: 'flex', justifyContent: 'center'}}>
                  <Paper elevation={1} sx={{
                    width: { xs: '350px', sm: '300px' }, // Adjust width as needed for different screen sizes
                    padding: '20px',
                    textAlign: 'center',
                    borderRadius: '16px',
                  }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                      <Box sx={{ float: 'left', pl: '2px', pr: '2px', color: 'gray', marginBottom: '10px', width: '100%',  display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <div>account</div>
                        <div>amount</div>
                        <div>tx</div>
                        <div>type</div>
                      </Box>

                      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px', width: '100%' }}>
                        {currentTrades.map((trade, index) => (
                          <Item
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              pl: '10px',
                              pr: '10px',
                              maxHeight: '35px',
                            }}
                            key={index}
                          >
                            <p>{`${trade.user_address.slice(0, 3)}...${trade.user_address.slice(-3)}`}</p>
                            <p>{formatTokenAmount(trade.token_amount)}</p>
                            <a
                              href={`https://testnet.ftmscan.com/tx/${trade.transaction_hash}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <p>{`${trade.transaction_hash.slice(0, 2)}..${trade.transaction_hash.slice(-2)}`}</p>
                            </a>
                            <p style={{ color: trade.transaction_type === '1' ? '#86efac' : '#fca5a5' }}>{trade.transaction_type === '1' ? 'buy' : 'sell'}</p>
                          </Item>
                        ))}

                        <Pagination
                          sx={{mt: '10px'}}
                          count={totalPages}
                          page={currentPage}
                          onChange={(event, value) => setCurrentPage(value)}
                          showFirstButton
                          showLastButton
                          siblingCount={0}
                          boundaryCount={1}
                          renderItem={(item) => {
                            if (item === '...') {
                              return <PaginationItem {...item} />;
                            } else {
                              return <PaginationItem {...item} onClick={() => setCurrentPage(item.page)} />;
                            }
                          }}
                        />
                      </Box>
                    </Box>
                  </Paper>
          </Grid>

          {/* Second Box (new one) */}
          <Grid item>
            <Paper
              elevation={4}
              sx={{
                height: 320,
                width: 350,
                borderRadius: "16px",
                boxShadow: `rgba(183, 48, 255, 0.15) 0px 1px 8rem`,
              }}
            >
              <MarketBar address={address} />
              <TokenStats address={address} />
            </Paper>
          </Grid>
        </Grid>

      </Box>
  );
}