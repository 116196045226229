import './App.css';
import { ThemeProvider, CssBaseline } from '@mui/material'
import customtheme from './assets/theme'

import ResponsiveAppBar from "./components/NavBar";

//router
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";

//context
import { ProtocolProvider } from "./context/ProtocolContext"

//pages
import Home from "./pages/index"
import TradePage from "./pages/trade"


function App() {
  return (
    // <div className="App">
      <ProtocolProvider>
      <ThemeProvider theme={customtheme}>
      <CssBaseline />
      <Router>
      <ResponsiveAppBar />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path="/trade/:address" element={<TradePage />} />
        </Routes>
      </Router>
      </ThemeProvider>
      </ProtocolProvider>

    // </div>
  );
}

export default App;