import { createClient } from '@supabase/supabase-js';



// const supabaseURL=process.env.supabaseURL;
// const supabaseAnonKey= process.env.supabaseAnonKey;
const supabaseURL = 'https://hodvmtqypaahwrydvghb.supabase.co';
const supabaseAnonKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImhvZHZtdHF5cGFhaHdyeWR2Z2hiIiwicm9sZSI6InNlcnZpY2Vfcm9sZSIsImlhdCI6MTcxMjI0MTU5OCwiZXhwIjoyMDI3ODE3NTk4fQ.lg62y49I7lIZSh9pQUqPqHjdO_n2k9ynGrQEJoQRHXc';

export const supabase = createClient(supabaseURL, supabaseAnonKey);



// fetch tokens information
export const fetchTokenInfo = async (address) => {
  try {
    const { data, error } = await supabase
      .from('bonding_curve_tokens')
      .select('*')
      .eq('token_address', address)
      .single();
      
    if (error) {
      throw error;
    }
    return data;
  } catch (error) {
    throw error;
  }
};

// upload created tokens
export const uploadToken = async (tokenAddress, tokenName, tokenSymbol, imageLink, description, website, twitter, telegram, liquidity ) => {
    try {
      const { data: response, error } = await supabase.from('bonding_curve_tokens').insert({
        token_address: tokenAddress.toString(),
        token_name: tokenName.toString(),
        token_symbol: tokenSymbol.toString(),
        image_link: imageLink.toString(),
        description: description.toString(),
        website: website.toString(),
        twitter: twitter.toString(),
        telegram: telegram.toString(),
        liquidity: liquidity.toString()
      });
      if (error) {
        throw error;
      }
      return response;
    } catch (error) {
      throw error;
    }
};

// upload transactions for trades list
export const uploadTransaction = async ( tokenAddress, transactionType, userAddress, tokenAmount, transactionPrice, transactionEvent ) => {
    try {
      const { data: response, error } = await supabase.from('token_transactions').insert({
        token_address: tokenAddress.toString(),
        transaction_type: transactionType.toString(),
        user_address: userAddress.toString(),
        token_amount: tokenAmount.toString(),
        transaction_price: transactionPrice.toString(),
        transaction_hash: transactionEvent.toString(),
      });
      if (error) {
        throw error;
      }
      return response;
    } catch (error) {
      throw error;
    }
};

// gets all tokens by CREATION DATE
export const fetchTokensFromDatabase = async () => {
  try {
    const { data, error } = await supabase
      .from('bonding_curve_tokens')
      .select('*')
      .order('id', { ascending: false }); // Order by created_at column in descending order
    if (error) {
      throw error;
    }
    return data;
  } catch (error) {
    throw error;
  }
};

// most recent trade for navbar
export const getMostRecentTrade = async () => {
  try {
    // Query to select the most recent transaction for each token address
    const { data: transactionsData, error: transactionsError } = await supabase
      .from('token_transactions')
      .select('token_address', { distinct: true })
      .select('id', { distinct: true })
      .order('token_address')
      .order('id', { ascending: false })
      .limit(1);

      return { transactionsData, transactionsError };
} catch (e) {
  console.log("error", e);
  return { transactionsData: null, transactionsError: e };
}
}

// get for navbar
export const fetchTxTradesFromDatabase = async () => {
  try {
    const { data: tokensData, error: tokensError } = await supabase
      .from('token_transactions')
      .select('*');

    if (tokensError) {
      throw tokensError;
    }

    const { data: transactionsData, error: transactionsError } = await supabase
      .from('token_transactions')
      .select('*')
      .order('id', { ascending: false }); // Order transactions by ID in descending order

    if (transactionsError) {
      throw transactionsError;
    }

    // Organize transactions by token_address
    const transactionsByToken = transactionsData.reduce((acc, transaction) => {
      if (!acc[transaction.token_address]) {
        acc[transaction.token_address] = [];
      }
      acc[transaction.token_address].push(transaction);
      return acc;
    }, {});

    // Associate each token with its most recent transaction
    const combinedData = tokensData.map(token => {
      const tokenTransactions = transactionsByToken[token.token_address] || [];
      const mostRecentTransaction = tokenTransactions.length > 0 ? tokenTransactions[0] : null;

      return {
        ...token,
        mostRecentTransaction
      };
    });

    // Sort tokens by the most recent transaction ID
    combinedData.sort((a, b) => {
      if (!a.mostRecentTransaction && !b.mostRecentTransaction) {
        return 0;
      } else if (!a.mostRecentTransaction) {
        return 1;
      } else if (!b.mostRecentTransaction) {
        return -1;
      } else {
        return b.mostRecentTransaction.id - a.mostRecentTransaction.id;
      }
    });

    return combinedData;
  } catch (error) {
    throw error;
  }
};

// get most recent token created for navbar
export const getMostRecentTokenCreation = async () => {
  try {
    const { data: latestTokenData, error: tokenError } = await supabase
      .from('bonding_curve_tokens')
      .select('*')
      .order('timestamp', { ascending: false }) // Order by timestamp column in descending order
      .limit(1);
    console.log("Fetch result:", { latestTokenData, tokenError }); // Log the entire fetch result
    return { latestTokenData, tokenError };
  } catch (e) {
    console.log("Error fetching token data:", e); // Log any errors
    return { latestTokenData: null, tokenError: e };
  }
};


// gets all tokens by TX most recent
export const fetchTxTokensFromDatabase = async () => {
  try {
    const { data: tokensData, error: tokensError } = await supabase
      .from('bonding_curve_tokens')
      .select('*');

    if (tokensError) {
      throw tokensError;
    }

    const { data: transactionsData, error: transactionsError } = await supabase
      .from('token_transactions')
      .select('*')
      .order('id', { ascending: false }); // Order transactions by ID in descending order

    if (transactionsError) {
      throw transactionsError;
    }

    // Organize transactions by token_address
    const transactionsByToken = transactionsData.reduce((acc, transaction) => {
      if (!acc[transaction.token_address]) {
        acc[transaction.token_address] = [];
      }
      acc[transaction.token_address].push(transaction);
      return acc;
    }, {});

    // Associate each token with its most recent transaction
    const combinedData = tokensData.map(token => {
      const tokenTransactions = transactionsByToken[token.token_address] || [];
      const mostRecentTransaction = tokenTransactions.length > 0 ? tokenTransactions[0] : null;

      return {
        ...token,
        mostRecentTransaction
      };
    });

    // Sort tokens by the most recent transaction ID
    combinedData.sort((a, b) => {
      if (!a.mostRecentTransaction && !b.mostRecentTransaction) {
        return 0;
      } else if (!a.mostRecentTransaction) {
        return 1;
      } else if (!b.mostRecentTransaction) {
        return -1;
      } else {
        return b.mostRecentTransaction.id - a.mostRecentTransaction.id;
      }
    });

    return combinedData;
  } catch (error) {
    throw error;
  }
};

// gets token trades
export const fetchTradesFromDatabase = async (address) => {
  try {
    const { data, error } = await supabase
      .from('token_transactions')
      .select('*')
      .eq('token_address', address)
      .order('timestamp', { ascending: false }) // Order by timestamp in descending order
      // .range(0, 11); // Retrieve only the first 12 records
    if (error) {
      throw error;
    }
    return data;
  } catch (error) {
    throw error;
  }
};

// Import supabase
export const fetchChartData = async (address) => {
  try {
    const { data, error } = await supabase
      .from('token_transactions')
      .select('transaction_price, timestamp') // Select only transaction_price and timestamp columns
      .eq('token_address', address)
      .order('timestamp', { ascending: false }); // Order by timestamp in descending order

    if (error) {
      throw error;
    }
    return data;
  } catch (error) {
    throw error;
  }
};