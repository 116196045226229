import React, { useState, useEffect, useContext } from "react";

// styling imports
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";

// import components
import LockStepper from "./LockStepper.js";

const LockArea = () => {
  return (
    <Box
      sx={{
        width: "100%",
        pr: "12px",
        pl: "10px",
        display: 'flex', // Make the container flex
        flexDirection: 'column', // Set flex direction to column
        alignItems: 'stretch', // Stretch items along the cross-axis
      }}
    >
      <Paper elevation={1} sx={{ flex: 1, borderRadius: '16px' }}>
        <Box sx={{ pl: "20px", pr: "20px", pt: "20px", pb: "20px" }}>
          <LockStepper />
          {/* Add other components as needed */}
        </Box>
      </Paper>
    </Box>
  );
};

export default LockArea;