import React, { useRef, useEffect, useContext, useState } from 'react';
import { ColorType, createChart } from 'lightweight-charts';
import { ProtocolContext } from "../context/ProtocolContext.js";

import { fetchChartData, fetchTokenInfo } from "../components/services/api.js";

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const ChartArea = ({ address }) => {
    const {
        getTokenPrice,
        tokenPrice,
    } = useContext(ProtocolContext);

    const chartContainerRef = useRef();
    const chartInstanceRef = useRef();
    const [chartData, setChartData] = useState([]);
    const [tokenInfo, setTokenInfo] = useState([]);

    useEffect(() => {
        const loadChartData = async () => {
          try {
            const data = await fetchChartData(address); // Fetch chart data
            console.log("Fetched data:", data); // Log fetched data to console
            setChartData(data);
      
            // Fetch token information from Supabase
            const tokenInfo = await fetchTokenInfo(address);
            setTokenInfo(tokenInfo);
          } catch (error) {
            console.error('Error fetching chart data:', error);
          }
        };
      
        loadChartData();
      
        // Cleanup function...
      }, []);

    useEffect(() => {
        // Remove the previous chart instance if it exists
        if (chartInstanceRef.current) {
            chartInstanceRef.current.remove();
            chartInstanceRef.current = null;
        }
    
        if (chartContainerRef.current && chartData.length > 0) {
            chartInstanceRef.current = createChart(chartContainerRef.current, {
                width: chartContainerRef.current.offsetWidth,
                height: 400,
                layout: {
                    background: { type: ColorType.Solid, color: '#262b32' }, // Set the background color to black
                    chartRightAxis: {
                        color: 'red' /* Change 'red' to the color you desire */
                    },
                    textColor: 'white'
                    
                    // grid: {
                    //     vertLines: {
                    //         color: 'red', // Set vertical grid line color to match background
                    //     },
                    //     horzLines: {
                    //         color: 'blue', // Set horizontal grid line color to match background
                    //     },
                    // },
                },
                timeScale: {
                    timeVisible: true,
                    secondsVisible: false,
                    tickMarkFormatter: (time, tickMarkType, locale) => {
                      const date = new Date(time * 1000);
                      return date.getHours().toString();
                    },
                  },
            });

            const newSeries = chartInstanceRef.current.addAreaSeries({
                lineColor: '#a083e1', // Adjust line color
                topColor: '#5901fe',
                bottomColor: "rgba(41, 98, 255, 0.28)",

            });
    
            const series = chartInstanceRef.current.addLineSeries({
                color: '', // Adjust line color
                lineWidth: 2, // Adjust line width
                crossHairMarkerVisible: false, // Hide crosshair marker
            });
    
            // Convert fetched data into required format
            const formattedData = chartData.map(item => ({
                time: new Date(item.timestamp).getTime(),
                value: parseFloat(item.transaction_price),
            }));
    
            // Sort the data by timestamp in ascending order
            formattedData.sort((a, b) => a.time - b.time);
    
            newSeries.setData(formattedData);
        }
    
        // Cleanup function...
        return () => {
            if (chartInstanceRef.current) {
                chartInstanceRef.current.remove();
                chartInstanceRef.current = null;
            }
            // console.log("CHART DATA", chartData)
        };
    }, [chartData]);

    return (
        <>
            <Box sx={{ 
                display: 'flex', 
                justifyContent: 'center', 
                pl: '25px',
                pb: '25px',
                pr: '25px',
                pt: '25px',
                
            }}>
                <Grid container spacing={2}>
                    <Grid>          
                    <Stack direction="row">
                        <Typography sx={{ mt: '25px', textAlign: 'left', color: 'white', fontFamily: 'bold', fontSize: {xs: 14, sm: 12, md: 12 }}}>{tokenInfo.token_name}</Typography>
                        <Typography sx={{ mt: '25px', ml: '20px', textAlign: 'left', color: 'rgb(134, 239, 172)', fontFamily: 'bold', fontSize: {xs: 14, sm: 12, md: 12 }}}>{"Ticker: " + tokenInfo.token_symbol}</Typography>
                        <Typography sx={{ mt: '25px', ml: '20px', textAlign: 'left', color: 'white', fontFamily: 'bold', fontSize: {xs: 14, sm: 12, md: 12 }}}>{"CA: " + tokenInfo.token_address}</Typography>
                    </Stack>  
                        <Box ref={chartContainerRef} sx={{ minWidth: {xs: '380px', sm: '300px', md: '780px' }}}></Box>                   
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default ChartArea;