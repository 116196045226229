import React, { Component } from "react";

import Core from "../components/Core";
import Footer from "../components/Footer";
// import Pagenate from "../components/Pagenate"

import Layout from "../components/Layout";

export default class Home extends Component {
	render() {
		return (
			<div>
				<Layout>
                <Core />
				</Layout>
			</div>
		);
	}
}
