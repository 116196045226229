import React, { useRef, useEffect, useContext } from 'react';

// import mui styling
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

// import components
import LockArea from "./Forms/LockArea.js";
import ManageArea from "./Forms/ManageArea.js";



export default function Core() {

  const [switchTabs, setSwitchTabs] = React.useState(false);
  const handleSwitchTabs = (event, newValue) => {
    setSwitchTabs(newValue);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
        justifyContent: "center",
        paddingTop: "35px",
        "& > :not(style)": {
          m: 8,
          width: 420,
          borderRadius: "16px",
          paddingBottom: "12px",
        },
      }}
    >
      <Paper
        elevation={4}
        sx={{ boxShadow: `rgba(183, 48, 255, 0.18) 0px 1px 8rem` }}
      >
        <Box sx={{ pl: "15px", pt: "15px", float: "left" }}>
          <Tabs
            sx={{
              "& .MuiTabs-indicator": { display: "none" },
              "& button.Mui-selected": {
                color: (theme) => theme.palette.primary.main,
              },
              "& button": {
                borderRadius: 2,
                color: (theme) => theme.palette.primary.grey,
              },
              "& button.Mui-selected": {
                color: (theme) => theme.palette.primary.main,
              },
              "& button:active": {
                color: (theme) => theme.palette.primary.mainLight,
              },
              "& button.Mui-selected:hover": {
                color: (theme) => theme.palette.primary.mainLight,
              },
            }}
            value={switchTabs}
            onChange={handleSwitchTabs}
          >
            <Tab
              disableRipple
              variant="text"
              label="[ Create New Coin ]"
              value={true}
              sx={{
                fontSize: "14px",
                color: 'white',
                fontWeight: "bold",
                "&:hover": { backgroundColor: "transparent" },
                "&:focus": { backgroundColor: "transparent" },
              }}
            >
              Create Token
            </Tab>
            <Tab
              disableRipple
              variant="text"
              label="Buy Coin"
              value={false}
              sx={{
                fontSize: "14px",
                color: 'white',
                fontWeight: "bold",
                "&:hover": { backgroundColor: "transparent" },
                "&:focus": { backgroundColor: "transparent" },
              }}
            >
              Manage
            </Tab>
          </Tabs>
        </Box>

        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Box sx={{ width: 420, pt: "5px" }}>
              {switchTabs ? <LockArea /> : <ManageArea />}
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
}