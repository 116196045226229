import React, { useState, useEffect, useContext } from 'react';

import { Box, Button, Grid, TextField, Typography} from '@mui/material';
import { ProtocolContext } from "../context/ProtocolContext.js";
import { fetchTokensFromDatabase } from '../components/services/api.js';


export default function Core({ address }) {

    const [ tokenImage, setTokenImage ] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await fetchTokensFromDatabase();
                const token = data.find(token => token.token_address === address);
                setTokenImage(token);
            } catch (error) {
                console.error('Error fetching token data:', error);
            }
        };
        fetchData();
    }, [address]);

    const {
        purchaseToken,
        sellToken,
        currentTokenPrice
    } = useContext(ProtocolContext);

    const [amount, setAmount] = React.useState('');
    const [action, setAction] = useState('BUY');
    const [totalPrice, setTotalPrice] = useState(0);

    useEffect(() => {
        const calculateTotalPrice = async () => {
            if (amount !== '') {
                let price;
                if (action === 'BUY') {
                    price = await currentTokenPrice(address, amount)
                    setTotalPrice(price);
                } else if (action === 'SELL') {
                    price = await currentTokenPrice(address, amount)
                    setTotalPrice(price);
                }
            }
        };
        calculateTotalPrice();
    }, [amount, address, action]);

    const handleAmountChange = (event) => {
        setAmount(event.target.value);
    };

    const handle500k = () => {
        setAmount('500000');
    };
    const handle1M = () => {
        setAmount('1000000');
    };
    const handle1B = () => {
        setAmount('1000000000');
    };
    const handle50B = () => {
        setAmount('50000000000');
    };

    const handlePurchase = () => {
        setAction('BUY');
    };

    const handleSell = () => {
        setAction('SELL');
    };

    const handlePlaceTrade = () => {
        if (action === 'BUY') {
            purchaseToken(address, amount);
        } else if (action === 'SELL') {
            sellToken(address, amount);
        }
    };

    return (
        <Box
            sx={{
                padding: 2,
                borderRadius: "16px",
                border: "none",
                background: "#241f26"
            }}
        >
            <Button onClick={handlePurchase} disableRipple sx={{
                '&:hover': {
                    color: 'white',
                    backgroundColor: action === 'BUY' ? '#4ade80' : '',
                },
                background: action === 'BUY' ? '#4ade80' : '#1f2937',
                mr: 2,
                borderRadius: 1,
                minWidth: '150px',
                minHeight: '50px',
                color: action === 'BUY' ? 'black' : 'lightgray'
            }}>BUY</Button>
            <Button onClick={handleSell} disableRipple sx={{
                '&:hover': {
                    color: 'white',
                    backgroundColor: action === 'SELL' ? '#f87171' : '',
                },
                background: action === 'SELL' ? '#f87171' : '#1f2937',
                borderRadius: 1,
                minWidth: '150px',
                minHeight: '50px',
                color: action === 'BUY' ? 'lightgray' : 'white'
            }}>SELL</Button>
            <Box sx={{ mt: 2, maxWidth: '300px', backgroundColor: '' }}>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs>
                    <TextField
                                label="Amount (Tokens)"
                                variant="outlined"
                                value={amount}
                                onChange={handleAmountChange}
                                sx={{minWidth: '316px'}}
                                InputProps={{
                                    endAdornment: (
                                        <div style={{ position: 'relative' }}>
                                            <img
                                                src={tokenImage ? `https://scarlet-rapid-antelope-514.mypinata.cloud/ipfs/${tokenImage.image_link}` : ''}
                                                alt=""
                                                style={{
                                                    width: 24,
                                                    height: 24,
                                                    borderRadius: '50%',
                                                    position: 'relative', // Ensure the image is positioned relative to its container
                                                }}
                                            />
                                            {action === 'SELL' && ( // Render red overlay if action is 'SELL'
                                                <div
                                                    style={{
                                                        position: 'absolute',
                                                        top: 0,
                                                        left: 0,
                                                        width: '95%',
                                                        height: '80%',
                                                        backgroundColor: 'rgba(255, 0, 0, 0.1)', // Red overlay color with 50% opacity
                                                        borderRadius: '50%',
                                                    }}
                                                />
                                            )}
                                            {action === 'BUY' && ( // Render green overlay if action is 'BUY'
                                                <div
                                                    style={{
                                                        position: 'absolute',
                                                        top: 0,
                                                        left: 0,
                                                        width: '95%',
                                                        height: '80%',
                                                        backgroundColor: 'rgba(0, 255, 0, 0.1)', // Green overlay color with 50% opacity
                                                        borderRadius: '50%',
                                                    }}
                                                />
                                            )}
                                        </div>
                                    )
                                }}
                            />
                    </Grid>
                </Grid>
                <Grid item sx={{mb: '70px'}}>
                    <Button variant="default" sx={{float: 'left', backgroundColor: '#1b1d28', mt: '10px'}} onClick={handle500k}>500k</Button>
                    <Button variant="default" sx={{float: 'left', backgroundColor: '#1b1d28', mt: '10px', ml: '5px'}} onClick={handle1M}>1M</Button>
                    <Button variant="default" sx={{float: 'left', backgroundColor: '#1b1d28', mt: '10px', ml: '5px'}} onClick={handle1B}>1B</Button>
                    <Button variant="default" sx={{float: 'left', backgroundColor: '#1b1d28', mt: '10px', ml: '5px'}} onClick={handle50B}>50B</Button>
                </Grid>
                <Grid item sx={{}}>
                <Typography sx={{color: 'lightgray'}}>{totalPrice} ETH</Typography>
                </Grid>
                <Button onClick={handlePlaceTrade} sx={{
                    '&:hover': {
                        color: 'black',
                        backgroundColor: '#bbf7d0',
                    },
                    background: '#4ade80',
                    textAlign: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                    borderRadius: 1,
                    minWidth: '315px',
                    minHeight: '45px',
                    marginTop: '30px',
                    color: 'black'
                }}>Place Trade</Button>
            </Box>
        </Box>
    );
}