import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from 'react-router-dom'; // Import useNavigate
// import mui styling
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Container, Hidden } from "@mui/material";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";

import Modal from "@mui/material/Modal";

import { ProtocolContext } from "../context/ProtocolContext";

import ChainModal from "./Modals/ChainModal";


import { fetchTxTradesFromDatabase, getMostRecentTokenCreation } from "../components/services/api.js"

// chain image imports header
import base from "../assets/images/base.svg";
import ftm from "../assets/images/ftm.svg";


const Homecomponent = styled("div")(({ theme }) => ({
  "& .btnlft": {
    marginLeft: "auto",
  },
  "& .MuiButton-outlinedInherit": {

    textTransform: "capitalize",
  },
  "& .MuiButton-outlinedInherit:hover": {
    color: "#f16b45 ",
  },
}));

// modal styling
const styleModal = {
  position: "absolute",
  top: "38%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 450,
  height: 340,
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "25px",
  boxShadow: 30,
  border: "2px solid #5901fe",
  p: 3,
};

 // Function to add shake class and remove it after a duration
 const addShakeClass = (element) => {
  element.classList.add('shake');
  setTimeout(() => {
    element.classList.remove('shake');
  }, 1000); // Adjust the duration as needed
};

// <Item id={`token_${token.token_address}`} className={recentTokenAddress === token.token_address ? 'shake' : ''}>

export default function ButtonAppBar() {
  const navigate = useNavigate(); // Initialize useNavigate

  // Function to redirect to the home page
  const redirectToHome = () => {
    navigate('/');
  };

  const {
    selectedChain,
    setSelectedChain,
    connectWallet,
    currentAccount,
    openChains,
    setOpenChains,
    handleOpenChainlist,
    handleCloseChainlist,
    pleaseUseMetamask
  } = useContext(ProtocolContext); // need to make a switch for chain name  handleCloseChainlist, handleOpenChainlist
  const [userName, setUserName] = useState();

  const [latestTokenData, setLatestTokenData] = useState(null);
  const [transactionsData, setTransactionsData] = useState();
  const [allTokens, setAllTokens] = useState(null);
  const [tokenCount, setTokenCount] = useState(null);
  const [recentTokenAddress, setRecentTokenAddress] = useState(null); // most recent trade

  // imgs per chain
  const logosHeaderEnums = {
    base: <img style={{ width: 25, height: 25 }} src={base} alt="base" />,
    ftm: <img style={{ width: 25, height: 25 }} src={ftm} alt="f" />,
  };

  // chain select
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  // Handle the navbar data
  useEffect(() => {
    // Fetch most recent token creation
    getMostRecentTokenCreation() //getMostRecentTokenCreation
      .then(({ latestTokenData, tokenError }) => {
        setLatestTokenData(latestTokenData);
      })
      .catch((error) => {
        console.error("Error fetching token data:", error);
      });
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const newData = await fetchTxTradesFromDatabase();
        if (newData) {
          setAllTokens(newData);
          setTokenCount(newData.length); // Update token count
          
          // Apply shake animation to the most recent item
          if (newData.length > 0) {
            // console.log("GEEEEEEEEEEEEEEEEEEEEEEERRRRRRR", newData[0])
            // const mostRecentTokenAddress = newData[0];
            // if (transactionsData.address !== mostRecentTokenAddress.address) {
              setTransactionsData(newData[0]);
              // const mostRecentItem = document.getElementById(`token_${mostRecentTokenAddress}`);
              // if (mostRecentItem) {
              //   addShakeClass(mostRecentItem);
              // }
            // }
          }
        } else {
          console.error('Error: Received null data from fetch.');
        }
      } catch (error) {
        console.error('Error fetching tokens:', error);
      }
    };
    // Fetch data on component mount and whenever the length of the fetched data changes
    fetchData();
  }, []);

  useEffect(() => {
    const data = window.localStorage.getItem("LOCAL_CHAIN_SELECTED");
    setSelectedChain(JSON.parse(data));
  }, []);

  useEffect(() => {
    if (selectedChain != null) {
      window.localStorage.setItem(
        "LOCAL_CHAIN_SELECTED",
        JSON.stringify(selectedChain),
      ); //cannot parse json objects or reference?
    }
  }, [selectedChain]);

  useEffect(() => {
    if (currentAccount != null)
      setUserName(
        `${currentAccount.slice(0, 6)}...${currentAccount.slice(38)}`,
      );
  }, [currentAccount]);

  useEffect(() => {
    if (currentAccount != null)
      setUserName(
        `${currentAccount.slice(0, 6)}...${currentAccount.slice(38)}`,
      );
  }, [currentAccount]);

  // State for How It Works modal
  const [howItWorksOpen, setHowItWorksOpen] = useState(false);

  const handleHowItWorksOpen = () => {
    setHowItWorksOpen(true);
  };

  const handleHowItWorksClose = () => {
    setHowItWorksOpen(false);
  };

  const formatDate = (timestampString) => {
    const date = new Date(timestampString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  return (
    <Homecomponent>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar
          position="static"
          sx={{ background: "transparent", boxShadow: "none" }}
        >
          <Container>
            <Toolbar>
              <Typography sx={{ fontWeight: "bold" }} onClick={redirectToHome} style={{ cursor: "pointer" }}>
              🐸 pump fast
              </Typography>

              <Button
                variant="contained"
                sx={{
                  textTransform: 'none',
                  textDecoration: 'none',
                  ml: '20px',
                  // maxHeight: '30px',
                  color: 'black',
                  backgroundColor: '#7eff7a',
                  '&:hover': {
                    backgroundColor: 'rgba(126, 255, 122, 0.5)', // Light green with 50% opacity
                  }
                }}
              >
              {
                transactionsData ? 
                (transactionsData.transaction_type === 1 ? "Sell" : "Buy") + " " + 
                transactionsData.token_amount + " of " + 
                (transactionsData.token_address.length > 6 ? 
                  transactionsData.token_address.slice(0, 3) + "..." + transactionsData.token_address.slice(-3) : 
                  transactionsData.token_address) 
                : "loading..."
              }
              </Button>

              <Button
                  variant="contained"
                  sx={{
                      textTransform: 'none',
                      textDecoration: 'none',
                      ml: ['0', '20px'], // Adjust margin for mobile and desktop views
                      display: ['none', 'inline-block'], // Hide button on mobile, show on desktop
                      // maxHeight: '30px',
                      color: 'black',
                      backgroundColor: 'rgba(175, 143, 247, 0.9)', // Light purple with 50% opacity
                      '&:hover': {
                          backgroundColor: 'rgba(175, 143, 247, 0.5)', // Same as default state
                      }
                  }}
              >
                  {latestTokenData && latestTokenData.length > 0 && (
                      <>
                          {latestTokenData ? latestTokenData[0].token_symbol + " created " + formatDate(latestTokenData[0].timestamp) : "loading..."}
                      </>
                  )}
              </Button>

              <Hidden mdDown>
                <div className="btnlft">
                  <Stack direction="row" spacing={2}>

                      {/* "How It Works" link */}
                      <Button 
                        variant="text" 
                        onClick={handleHowItWorksOpen} 
                        sx={{ 
                          "&:hover": { 
                            backgroundColor: "transparent", 
                            opacity: 0.5,
                          } 
                        }}
                      >
                        How It Works
                      </Button>

                    {/* chain id */}
                    <Button
                      variant="outlined"
                      onClick={() => handleOpenChainlist()}
                    >
                      {selectedChain
                        ? logosHeaderEnums[selectedChain.img]
                        : null}
                    </Button>

                    <Box sx={{}}>
                      {currentAccount ? (
                        <>
                          <Button style={{ fontWeight: 'bold', marginRight: '10px', minWidth: '180px' }}>
                            {userName}
                          </Button>
                        </>
                      ) : (
                        <>
                          <Button onClick={() => connectWallet()} style={{ fontWeight: 'bold', marginRight: '10px', minWidth: '180px' }}>
                            {pleaseUseMetamask ? ('Connect Wallet') : ('Connect Wallet')}
                          </Button>
                        </>
                      )}
                    </Box>
                  </Stack>
                </div>
              </Hidden>

            </Toolbar>

            {/* Modal for "How It Works" */}
            <Modal open={howItWorksOpen} onClose={handleHowItWorksClose}>
              <Box sx={styleModal}>
                <Typography variant="h6">How It Works</Typography>
                <Typography variant="body1">
                  Pump Fast prevents rugs by making sure that all created tokens are safe. Each token on  is a fair-launch with no presale and full transparency on allocation.
                </Typography>
                <Typography variant="body1">
                  <strong>Step 1:</strong> Choose the token you like
                </Typography>
                <Typography variant="body1">
                  <strong>Step 2:</strong> Buy the token on the selected chain
                </Typography>
                <Typography variant="body1">
                  <strong>Step 3:</strong> Sell at any time to lock in your profits or losses
                </Typography>
                <Typography variant="body1">
                  <strong>Step 4:</strong> When enough people buy, it reaches a market cap of $29k
                </Typography>
                <Typography variant="body1">
                  <strong>Step 5:</strong> $12k of liquidity is then deposited on SUSHISWAP and burned
                </Typography>
                {/* Add any other content as needed */}
              </Box>
            </Modal>

            <Modal open={openChains} onClose={handleCloseChainlist}>
              <Box sx={styleModal}>
                <ChainModal />
              </Box>
            </Modal>
          </Container>
        </AppBar>
      </Box>
    </Homecomponent>
  );
}