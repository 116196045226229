// abi and contracts by chain

import BondingCurveToken_abi from './BondingCurveToken.json'
import BondingCurveTokenFactory_abi from './BondingCurveTokenFactory.json'
import MockWETH_abi from './MockWETH.json'

// abi files
export const BondingCurveTokenABI = BondingCurveToken_abi.abi
export const BondingCurveTokenFactoryABI = BondingCurveTokenFactory_abi.abi
export const MockWETHABI = MockWETH_abi.abi


// testnet factory contracts by chain
// export const TimeLockFactoryGoerli = "0xB3F1D5E6DcAA54E97869e072feF20110A22bd24D"
export const BondingCurveTokenFactoryTest = "0x66Aa33A365a45310cA4eFD899dCDC4eBa4C6b194" // ftm testnet
export const BondingCurveTokenFactory = "0xEf087dd9C2ebab6902E4C3b05E4bB4c50faCdB75" // 0x3d98dd781bDdBfB7E027D9EC02590e14B4700DD8   0x23244Bc8b670A61C579cca1070e12B308ddbFDA7  0x12a6cDA8b975cee1A145Dd04861c8970482EAa59   0x4D682E1F9E0190ED5107807cdd409bFaca5d252E
export const MockWETH = "0x4d4a744CcFc5292F036c175BDB025405a28400fF" // mock weth for testing on fantom

// 0xd1f6F97d86f1e1f588Ad70AF35AF67A66d4A280D ftm testnet


// 0x2cD1Ef1A8cb35FD43C58B9678d4d2108EF847C8d
// 0x3d98dd781bDdBfB7E027D9EC02590e14B4700DD8 OLD

//export const TimeLockFactoryArbitrumGoerli = "0x3E27c9470f3CDF13e433ED039cdc1F92d5B797D2"
//export const TimeLockFactoryEvmos = ""
//export const TimeLockFactoryBinance = ""
//export const TimeLockFactoryFantom = ""