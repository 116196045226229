import React from "react";

// mui imports
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import InputBase from '@mui/material/InputBase';
import SearchIconButton from '@mui/icons-material/Search';

// import components
import ChainListFull from './ChainListFull';

const ExistingTokens = () => {

  return (
    
    <>
    <Box sx={{pr: '20px'}}>
    <InputBase
            sx={{
              ml: 1,
              pt: 1,
              pb: 1,
              pl: 1,
              pr: 1,
              flex: 1,
              width: '100%',
              backgroundColor: '#191919',
              borderRadius: '6px',
            }}
            placeholder="Select Network"
            inputProps={{ style: { fontSize: 14 } }}
            InputLabelProps={{ style: { fontSize: 14 } }}
            endAdornment={<SearchIconButton sx={{fontSize: '21px', color: 'grey'}} />}
          />
    </Box>

    <Box sx={{minHeight: '385px', maxHeight: '385px', minWidth: '100%', maxWidth: '100%', pr: '5px'}}>
    <Grid container rowSpacing={1} sx={{ mt: 1, justifyContent: 'center', alignItems: 'center' }}>
      <ChainListFull />
    </Grid>
    </Box>
    </>
  )
}

export default ExistingTokens