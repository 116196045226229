import React, { useState, useEffect, useContext} from 'react';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';

import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip"

import { Info } from 'lucide-react';
import { fetchTokensFromDatabase } from '../components/services/api.js'; // tokens quick load db
import { Twitter, Globe, Send  } from 'lucide-react';

import { ProtocolContext } from "../context/ProtocolContext";

const TokenStats = ({ address }) => {

    const { currentTokenSupply } = useContext(ProtocolContext);

    const [tokenData, setTokenData] = useState(null);
    const [bondingCurve, setBondingCurve] = useState();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await fetchTokensFromDatabase();
                const token = data.find(token => token.token_address === address);

                // just call get supply and get total supply / 1 trillion. At 1 trillion supply its 100% bonding curve currentTokenSupply
                const theCurve = await currentTokenSupply(address);
                setBondingCurve(theCurve);

                setTokenData(token);
            } catch (error) {
                console.error('Error fetching token data:', error);
            }
        };
        fetchData();
    }, [address]);

    if (!tokenData) {
        return <Typography>Loading token data...</Typography>;
    }

    // Calculate progress (mock data)
    const totalSupply = 1000000;
    const marketCap = 500000;
    const progress = (marketCap / totalSupply) * 100;

    // price per token = 0.00001

    // total supply = 215,625 tokens

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px', marginTop: '10px', border: 'transparent', borderRadius: '8px', boxShadow: 'rgba(183, 48, 255, 0.08) 0px 1px 8rem', width: '350px', backgroundColor: 'transparent' }}>
            <Box sx={{ display: 'flex', backgroundColor: '', minWidth: '100%' }}>
            <img 
            style={{ 

                width: '100px', 
                height: '100px', 
                marginBottom: '10px', 
                float: 'left', // Float the image to the left
                marginRight: '10px' // Add margin to the right to create space between the image and text
            }} 
            src={`https://scarlet-rapid-antelope-514.mypinata.cloud/ipfs/${tokenData.image_link}` || 'https://via.placeholder.com/100'} 
            alt="" 
        />
                <Box sx={{ marginLeft: '10px', textAlign: 'left' }}>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}> {/* Flex container */}
                    <Typography variant="p" sx={{ marginRight: '5px', fontWeight: 'bold', fontSize: '14px' }}>{tokenData.token_name || 'Unknown Token'}</Typography> {/* Add margin-right for spacing */}
                    <Typography variant="p" sx={{fontWeight: 'bold', fontSize: '14px'}}>{ '(ticker: ' + tokenData.token_symbol + ')' || 'NA'}</Typography>
                </div>
                <Stack direction="row" spacing={2}>
                    {tokenData && tokenData.website ? (
                        <a href={tokenData.website} target="_blank" rel="noopener noreferrer">
                            <Globe size={16} color="#3901fe"/>
                        </a>
                    ) : (
                        <Globe size={16} color="gray"/>
                    )}
                    {tokenData && tokenData.twitter ? (
                        <a href={tokenData.twitter} target="_blank" rel="noopener noreferrer">
                            <Twitter size={16} color="#3901fe"/>
                        </a>
                    ) : (
                        <Twitter size={16} color="gray"/>
                    )}
                    {tokenData && tokenData.telegram ? (
                        <a href={tokenData.telegram} target="_blank" rel="noopener noreferrer">
                            <Send size={16} color="#3901fe"/>
                        </a>
                    ) : (
                        <Send size={16} color="gray"/>
                    )}
                </Stack>
                <Typography variant="body1" sx={{ textAlign: 'left', mt: '10px', fontSize: '12px' }}>{tokenData.description || 'Token Description'}</Typography>
                </Box>
            </Box>
            <Box sx={{backgroundColor: '', mt: '20px', width: '100%'}}>
            <Stack direction="row" spacing={1}>
            <Typography variant="body2">Bonding Curve Progress {bondingCurve}%

            <Box sx={{float: 'right', pl: '5px'}}>
            <Tooltip title="when the market cap reaches $69k all the liquidity from the bonding curve will be deposited into Uniswap and be burned. progression increases as the price goes up.">
            <Info color="#5901fe" size="20px"/>
            </Tooltip>
            </Box>

            </Typography>
            </Stack>
            <LinearProgress sx={{ width: '100%', marginTop: '10px' }} variant="determinate" value={bondingCurve} />
            </Box>
        </Box>
    );
};

export default TokenStats;