import React, { useState, useEffect, useContext } from "react";

// MUI imports
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import IconButton from "@mui/material/IconButton";
import Modal from "@mui/material/Modal";
import Stack from '@mui/material/Stack';

// api imports
import axios from 'axios';

// luicide icons
import { Twitter, Globe, Send  } from 'lucide-react';

// submit the socials into the database
// ipfs url
// https://scarlet-rapid-antelope-514.mypinata.cloud/ipfs/

// import context
import { ProtocolContext } from "../../context/ProtocolContext.js";

export default function VerticalLinearStepper() {
  // import from context
  const {
    currentAccount,
    connectWallet,
    generateToken,
    genFormParams,
    setGenFormParams,
    contractLoading,
    contractLoaded,
    lockContractAddress,
  } = useContext(ProtocolContext);

  const [loading, setLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false); // socials modal
  const [isFinalModalOpen, setFinalModalOpen] = useState(false); // Tokens to buy modal

  // modal form
  const [twitter, setTwitter] = useState('');
  const [website, setWebsite] = useState('');
  const [telegram, setTelegram] = useState('');

  // error messages
  const [errors, setErrors] = useState([]);

  // Initial liq calc
  const [tokenAmount, setTokenAmount] = useState(0);
  const etherCostPerToken = 0.0000000000030152;
  

  const calculateCost = () => {
    const maxTokens = 800000000000; // 800 billion
    const minTokens = 100000;
    const cost = tokenAmount * etherCostPerToken;
    if (tokenAmount < minTokens) {
      return 'Error: Minimum token purchase is 100,000 tokens';
    } else if (tokenAmount > maxTokens) {
      return 'Error: Maximum token purchase limited to 800 billion tokens (80% of total supply)';
    } else {
      return cost;
    }
  };

  const handleSubmitCreateCoin = () => {
    let error = null;
  
    if (!genFormParams.name_) {
      error = "no name";
    } else if (!genFormParams.symbol_) {
      error = "no ticker";
    } else if (!file) {
      error = "no image";
    }
  
    if (error) {
      setErrors([error]); // Set errors state with the latest error message
      console.error("Please fill in all required fields");
      return;
    }
  
    // All required fields are filled, open the final modal to create the coin
    openModalFinal();
  };

  // step handler
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  // Function to open modal
  const openModal = () => {
    setIsModalOpen(true);
  };

  // Function to close modal
  const closeModal = () => {
    setIsModalOpen(false);
  };

  // final modal (initial liq buy)
  const openModalFinal = () => {
    setFinalModalOpen(true);
  };

  // Function to close final modal (initial liq buy)
  const closeModalFinal = () => {
    setFinalModalOpen(false);
  };

  const handleSubmitModal = () => {
    setGenFormParams((prev) => ({
      ...prev,
      twitter_: twitter,
      website_: website,
      telegram_: telegram,
    }));
    closeModal();
  };

  // handle pinata
  const [file, setFile] = useState(null);
  // swap to env variable soon!
  const JWT = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySW5mb3JtYXRpb24iOnsiaWQiOiIwNDgwOWQ5Yi1kNTQzLTRjZmMtYTE4OS0zY2RlOWRkNmRjMzAiLCJlbWFpbCI6ImdjZnVsbHN0YWNrZGV2QGdtYWlsLmNvbSIsImVtYWlsX3ZlcmlmaWVkIjp0cnVlLCJwaW5fcG9saWN5Ijp7InJlZ2lvbnMiOlt7ImlkIjoiRlJBMSIsImRlc2lyZWRSZXBsaWNhdGlvbkNvdW50IjoxfSx7ImlkIjoiTllDMSIsImRlc2lyZWRSZXBsaWNhdGlvbkNvdW50IjoxfV0sInZlcnNpb24iOjF9LCJtZmFfZW5hYmxlZCI6ZmFsc2UsInN0YXR1cyI6IkFDVElWRSJ9LCJhdXRoZW50aWNhdGlvblR5cGUiOiJzY29wZWRLZXkiLCJzY29wZWRLZXlLZXkiOiIzZTlhMjMyNzI2MDdkNDlkNThhYSIsInNjb3BlZEtleVNlY3JldCI6IjdiODVkNTgzY2JiZDVjMWEwYzhmNWVmYmZhZjljNjQwZTEzZmI5NWFjZTBiNjgxYmQxZTQzMGJlYjUwZTM2MTAiLCJpYXQiOjE3MTQxMzQwNDl9.GrVxG1CW6YZnxRrDTB_SfuyaTy63XqDpCG78CFge0X8"

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const pinFileToIPFS = async () => {
    if (!file) {
      console.error("No file selected");
      return;
    }

    const formData = new FormData();
    formData.append('file', file);

    const pinataMetadata = JSON.stringify({
      name: file.name, // Use file name as metadata
    });
    formData.append('pinataMetadata', pinataMetadata);

    const pinataOptions = JSON.stringify({
      cidVersion: 0,
    });
    formData.append('pinataOptions', pinataOptions);
    

    try {
      const res = await axios.post("https://api.pinata.cloud/pinning/pinFileToIPFS", formData, {
        maxBodyLength: Infinity,
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${JWT}`
        }
      });
      if (res.data && res.data.IpfsHash) {
        const ipfsHash_ = res.data.IpfsHash;
        setGenFormParams((prev) => ({
          ...prev,
          ipfsHash_: ipfsHash_,
        }));
        console.log("LINE 178 lock stepper: ", ipfsHash_);
      } else {
        console.error("Invalid response data or IpfsHash not found in response.");
      }
    } catch (error) {
      console.error(error);
    }
  }

  // token contract writables
  const handleSubmit = async (e) => {
    try {
      const { name_, symbol_, slope_, ipfsHash_, description_, website_, twitter_, telegram_, liquidity_ } = genFormParams;
      e.preventDefault();
      await generateToken();
    } catch (error) {
      console.error("generate error:", error);
    }
  };

  return (
    <Box sx={{ maxWidth: 400 }}>
      <Stepper activeStep={activeStep} orientation="vertical">
        <Step>
          <StepLabel>Connect Wallet</StepLabel>
          <StepContent>
            <Box>
              <Typography sx={{ float: "left" }}>
                Connect Wallet & Select Chain
              </Typography>
            </Box>
            <Box sx={{ mb: 2 }}>
              <div>
                {activeStep === 0 ? (
                  <>
                    {currentAccount ? (
                      <Button
                        variant="contained"
                        onClick={handleNext}
                        sx={{
                          mt: 1,
                          mr: 1,
                          float: "left",
                          mt: "5px",
                          minWidth: "225px",
                          maxWidth: "225px",
                        }}
                      >
                        Continue
                      </Button>
                    ) : (
                      <Button
                        variant="outlined"
                        sx={{
                          float: "left",
                          minWidth: "225px",
                          maxWidth: "225px",
                          mt: "15px",
                        }}
                        disabled={loading}
                        onClick={() => {
                          setLoading(true);
                          connectWallet();
                        }}
                      >
                        <Typography
                          sx={{ textTransform: "none", fontWeight: "600" }}
                        >
                          {loading ? (
                            <CircularProgress size={15} />
                          ) : (
                            "Connect Wallet"
                          )}
                        </Typography>
                      </Button>
                    )}
                  </>
                ) : activeStep === 2 ? (
                  <Button
                    variant="contained"
                    onClick={handleNext}
                    sx={{ mt: 1, mr: 1, float: "left", mt: "15px" }}
                  >
                    Finish
                  </Button>
                ) 
                : 
                (
                  <Button
                    variant="contained"
                    onClick={null} //openModalFinal ????
                    sx={{
                      mr: 1,
                      float: "left",
                      mt: "15px",
                      minWidth: "175px",
                      maxWidth: "175px",
                      minHeight: "36.5px",
                      maxHeight: "36.5px",
                    }}
                    disabled={contractLoading}
                  >
                    {contractLoading ? (
                      <CircularProgress size={15} />
                    ) : (
                      "Create coinvvvvvvvvvvvvvvvvvvvvvv"
                    )}
                  </Button>
                )}
              </div>
            </Box>
          </StepContent>
        </Step>
        <Step>
          <StepLabel>Create coin</StepLabel>
          <StepContent>
            <Box>
              <TextField
                id="filled-basic"
                size="small"
                label="name"
                variant="filled"
                disabled={contractLoaded}
                onChange={(e) => {
                  setGenFormParams((prev) => {
                    return Object.assign({}, prev, {
                      name_: e.target.value,
                    });
                  });
                }}
                sx={{ float: "left", width: "80%" }}
              />
              <TextField
                id="filled-basic"
                size="small"
                label="ticker"
                variant="filled"
                disabled={contractLoaded}
                onChange={(e) => {
                  setGenFormParams((prev) => {
                    return Object.assign({}, prev, {
                      symbol_: e.target.value,
                    });
                  });
                }}
                sx={{ float: "left", width: "80%", mt: "10px" }}
              />

              <TextField
                id="filled-basic"
                size="small"
                label="description"
                variant="filled"
                disabled={contractLoaded}
                onChange={(e) => {
                  setGenFormParams((prev) => {
                    return Object.assign({}, prev, {
                      description_: e.target.value,
                    });
                  });
                }}
                sx={{ float: "left", width: "80%", mt: "10px", mb: "10px" }}
              />
  
              <Box>
                <Stack sx={{minWidth: '80px'}}>
                <Box sx={{}}>
                <Typography sx={{ fontWeight: '300', mb: "5px", color: '#fff', maxWidth: '80px' }}> image </Typography>
                <input type="file" style={{ fontSize: '14px', maxWidth: '255px' }} onChange={handleFileChange} />
                </Box>
                </Stack>
              </Box>
              {/* <button onClick={pinFileToIPFS}>Pin File to IPFS</button> */}
              
            {errors.length > 0 && (
                <div style={{ flex: 1 }}>
                {errors.map((error, index) => (
                  <div style={{
                    border: '1px solid #e7806e', // border with red color
                    color: '#e7806e', // text color red
                    borderRadius: '4px', // rounded corners
                    padding: '8px', // padding
                    marginTop: '12px',
                    maxWidth: '255px'
                  }}>
                    {error}
                  </div>
                ))}
              </div>
            )}
            </Box>

              {contractLoaded ? (
                <Button
                  variant="contained"
                  onClick={handleNext}
                  sx={{
                    mt: 1,
                    mr: 1,
                    float: "left",
                    mt: "15px",
                    minWidth: "175px",
                    maxWidth: "175px",
                    minHeight: "36.5px",
                    maxHeight: "36.5px",
                  }}
                  disabled={contractLoading}
                >
                  {contractLoading ? (
                    <CircularProgress size={15} />
                  ) : (
                    "Next Step"
                  )}
                </Button>
              ) : (
                <Button
                  variant="contained"
                  onClick={() => {
                    handleSubmitCreateCoin();
                    pinFileToIPFS();
                  }}
                  sx={{
                    mt: 1,
                    mr: 1,
                    float: "left",
                    mt: "15px",
                    minWidth: "175px",
                    maxWidth: "175px",
                    minHeight: "36.5px",
                    maxHeight: "36.5px",
                  }}
                  disabled={contractLoading}
                >
                  {contractLoading ? (
                    <CircularProgress size={15} />
                  ) : (
                    "Create coin"
                  )}
                </Button>
              )}

            {/* =====================SOCIALS MODAL AREA ========================= */}
            <Modal
              open={isModalOpen}
              onClose={closeModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  bgcolor: "background.paper",
                  boxShadow: 24,
                  p: 4,
                  borderRadius: "20px",
                  
                }}
              >
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Tokens Social Details (optional)
                </Typography>

                <TextField
                  id="twitter-input"
                  label="Twitter"
                  variant="outlined"
                  value={twitter}
                  onChange={(e) => setTwitter(e.target.value)}
                  fullWidth
                />

                <TextField
                  id="website-input"
                  label="Website"
                  variant="outlined"
                  value={website}
                  onChange={(e) => setWebsite(e.target.value)}
                  fullWidth
                  sx={{ mt: 2 }}
                />
                
                <TextField
                  id="telegram-input"
                  label="Telegram"
                  variant="outlined"
                  value={telegram}
                  onChange={(e) => setTelegram(e.target.value)}
                  fullWidth
                  sx={{ mt: 2 }}
                />
                <Button variant="contained" onClick={handleSubmitModal} sx={{ mt: 2 }}>
                  Save
                </Button>
              </Box>
            </Modal>

             {/* =====================SOCIALS MODAL AREA END ========================= */}



             {/* =====================FINAL TOKEN SELECT MODAL AREA ========================= */}

            <Modal
              open={isFinalModalOpen}
              onClose={closeModalFinal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  bgcolor: "background.paper",
                  boxShadow: 24,
                  p: 4,
                  borderRadius: "20px",
                  border: "0.5px solid gray",
                  maxWidth: '400px'
                }}
              >
                <Typography id="modal-modal-title" variant="p" component="p" sx={{text: 'center', textAlign: 'center', mb: '10px'}}>
                Choose how many tokens you want to buy (optional)
                </Typography>

                <Typography variant="sm" component="p" sx={{color: 'gray', textAlign: 'center', mb: '20px', fontSize: '12px'}}>
                tip: its optional but buying a small amount of coins helps protect your coin from snipers
                </Typography>

                <Typography sx={{ fontSize: '12px', color: 'gray'}}>
                  Total Supply: 1,000,000,000,000
                </Typography>

                <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                  {/* tokenData.image_link ||  */}
                  <TextField
                    id="twitter-input"
                    label="Tokens"
                    variant="filled"
                    value={tokenAmount}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      if (inputValue === '' || isNaN(inputValue)) {
                        setTokenAmount(0);
                        setGenFormParams((prev) => ({
                          ...prev,
                          liquidity_: 0,
                        }));
                        return;
                      }
                      const inputTokenAmount = parseFloat(inputValue);
                      setTokenAmount(inputTokenAmount);
                      setGenFormParams((prev) => ({
                        ...prev,
                        liquidity_: inputTokenAmount,
                      }));
                    }}
                    inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                    fullWidth
                    sx={{ mb: "5px" }}
                  />
                </Box>

                <Typography sx={{pt: '20px', fontSize: '15px', color: 'gray'}}>
                Cost in ETH: {calculateCost()}
                </Typography>

                {contractLoaded ? (
                <Button
                  variant="contained"
                  onClick={handleNext}
                  fullWidth
                sx={{
                  mr: 1,
                  float: "left",
                  mt: "15px",
                  minHeight: "36.5px",
                  maxHeight: "36.5px",
                  mb: "20px",
                }}
                  disabled={contractLoading}
                >
                  {contractLoading ? (
                    <CircularProgress size={15} />
                  ) : (
                    "Next Step"
                  )}
                </Button>
              ) : (
                  <Button
                variant="contained"
                onClick={handleSubmit}
                fullWidth
                sx={{
                  mr: 1,
                  float: "left",
                  mt: "15px",
                  minHeight: "36.5px",
                  maxHeight: "36.5px",
                  mb: "20px",
                }}
                disabled={contractLoading}
                >
                {contractLoading ? (
                  <CircularProgress size={15} />
                ) : (
                  "Create Token"
                )}
              </Button>
              )}

              <Typography sx={{color: 'gray', textAlign: 'center', fontSize: '14px'}}>
              Deploy cost: $1 usd
              </Typography>

              </Box>
            </Modal>

             {/* =====================FINAL TOKEN SELECT MODAL AREA END ========================= */}

            
            <Box sx={{ mb: 2 }}>
              
              <div>
                {activeStep === 1 && (
                  <Button
                    variant="outlined"
                    onClick={handleBack}
                    sx={{ mt: 1, mr: 1, float: "left", mt: "15px" }}
                  >
                    Back
                  </Button>
                )}
              </div>

              <Button
              variant="secondary"
              onClick={openModal} // Open the modal when this button is clicked
              sx={{ mt: 1, mr: 1, float: "left", mt: "15px", gap: '12px' }}
            >
              <Twitter size="16"/> <Globe size="16" /> <Send size="16" /> Socials
            </Button>
            </Box>
          </StepContent>
        </Step>
        <Step>
          <StepLabel>Confirm</StepLabel>
          <StepContent>
            <Typography sx={{ pb: "10px", float: "left" }}>
              Token Launched:
            </Typography>
            <Box sx={{ float: "left" }}>
              <b sx={{ color: "black", float: "left" }}>
                {lockContractAddress != null
                  ? lockContractAddress.slice(0, 10) +
                    "..." +
                    lockContractAddress.slice(-10)
                  : ""}
              </b>{" "}
              <IconButton size="small">
                <ContentPasteIcon
                  onClick={() => {
                    navigator.clipboard.writeText(lockContractAddress);
                  }}
                  sx={{
                    color: "#32CD32",
                    textDecoration: "none",
                    fontSize: "15px",
                    cursor: "pointer",
                  }}
                />{" "}
              </IconButton>

              <div>
                {activeStep === 2 && (
                  <>
                    
                    <Button
                      variant="contained"
                      sx={{
                        mt: 1,
                        mr: 1,
                        float: "left",
                        mt: "15px",
                        minWidth: "115px",
                        maxWidth: "115px",
                        minHeight: "36.5px",
                        maxHeight: "36.5px",
                      }}
                    >
                      Share
                    </Button>
                    <Button
                      variant="outlined"
                      onClick={handleBack}
                      sx={{
                        mt: 1,
                        mr: 1,
                        float: "left",
                        mt: "15px",
                        minWidth: "105px",
                        maxWidth: "105px",
                        minHeight: "36.5px",
                        maxHeight: "36.5px",
                      }}
                    >
                      Back
                    </Button>
                  </>
                )}
              </div>
            </Box>
          </StepContent>
        </Step>
      </Stepper>
    </Box>
  );
}