import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Pagination from "@mui/material/Pagination";
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Grid from "@mui/material/Grid";
import { useNavigate } from 'react-router-dom';
import PaginationItem from '@mui/material/PaginationItem';

import { fetchTxTokensFromDatabase } from '../services/api.js'; // tokens quick load db

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  height: 'auto', // Adjusted height to fit image
  color: theme.palette.text.secondary,
  cursor: 'pointer',
}));

export default function LockManager() {
  const navigate = useNavigate();
  const [allTokens, setAllTokens] = useState([]);
  const [page, setPage] = useState(1);
  const [tokenCount, setTokenCount] = useState(0);
  const [recentTokenAddress, setRecentTokenAddress] = useState(null);
  const itemsPerPage = 5;

  // Function to add shake class and remove it after a duration
  const addShakeClass = (element) => {
    element.classList.add('shake');
    setTimeout(() => {
      element.classList.remove('shake');
    }, 1000); // Adjust the duration as needed
  };

  // LOAD NEXT
  useEffect(() => {
    const fetchData = async () => {
      try {
        const newData = await fetchTxTokensFromDatabase();
        setAllTokens(newData);
        setTokenCount(newData.length); // Update token count
        
        // Apply shake animation to the most recent item
        if (newData.length > 0) {
          const mostRecentTokenAddress = newData[0].token_address;
          if (recentTokenAddress !== mostRecentTokenAddress) {
            setRecentTokenAddress(mostRecentTokenAddress);
            const mostRecentItem = document.getElementById(`token_${mostRecentTokenAddress}`);
            if (mostRecentItem) {
              addShakeClass(mostRecentItem);
            }
          }
        }
      } catch (error) {
        console.error('Error fetching tokens:', error);
      }
    };

    // Fetch data on component mount and whenever the length of the fetched data changes
    fetchData();
  }, [allTokens]); // Include allTokens in the dependency array

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const goToTradePage = (tokenAddress) => {
    navigate(`/trade/${tokenAddress}`);
  };

  const totalPages = Math.ceil(tokenCount / itemsPerPage);

  // Determine which pages to display in the pagination
  let displayedPages = [];
  if (totalPages <= 4) {
    // Less than or equal to 4 pages, display all pages
    displayedPages = Array.from({ length: totalPages }, (_, i) => i + 1);
  } else {
    // More than 4 pages, display first two, last two, and "..." in the middle
    displayedPages = [1, 2, "...", totalPages - 1, totalPages];
  }

  return (
    <>
      <Box sx={{ maxWidth: 400 }}>
        <Grid container spacing={2}>
          {allTokens.slice((page - 1) * itemsPerPage, page * itemsPerPage).map((token, index) => (
            <Grid item xs={12} key={index} onClick={() => goToTradePage(token.token_address)}>
              <Item id={`token_${token.token_address}`} className={recentTokenAddress === token.token_address ? 'shake' : ''}>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
                  <Box
                    sx={{
                      flexShrink: 0,
                      mr: 2,
                      width: 65,
                      height: 63,
                      backgroundColor: 'primary.main',
                      backgroundImage: allTokens.length > 0 ? `url(https://scarlet-rapid-antelope-514.mypinata.cloud/ipfs/${token.image_link})` : '',
                      backgroundSize: 'cover', // Ensure image covers the box
                      backgroundPosition: 'center', // Center the image
                    }}
                  />
                  <Box sx={{ flexGrow: 1, pt: 0.5, pb: 0.5 }}>
                    <Typography variant="p" component="div">
                      {token.token_name || "Loading..."}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {token.description ? token.description.substring(0, 50) : "Loading..."}
                    </Typography>
                  </Box>
                </Box>
              </Item>
            </Grid>
          ))}
        </Grid>
      </Box>
      <Box sx={{ mt: 1, bg: 'red' }}>
        {totalPages > 1 && (
          <Pagination
            count={totalPages}
            page={page}
            onChange={handlePageChange}
            showFirstButton
            showLastButton
            siblingCount={0} // Hide adjacent page numbers
            boundaryCount={1} // Hide first and last page numbers
            renderItem={(item) => {
              if (item === "...") {
                return <PaginationItem {...item} />;
              } else {
                return <PaginationItem {...item} onClick={() => setPage(item.page)} />;
              }
            }}
          />
        )}
      </Box>
    </>
  );
}