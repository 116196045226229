// list of networks to connect to metamask
export const networks = {
    goerli:{
      chainId: `0x${Number(5).toString(16)}`,  //5
      chainName: "Goerli Testnet",
      nativeCurrency: {
        name: "GoerliETH",
        symbol: "ETH",
        decimals: 18
      },
      rpcUrls: ["https://ethereum-goerli.publicnode.com"],
      blockExplorerUrls: ["https://goerli.etherscan.io/"]
    },
    arbitrumGoerli:{
      chainId: `0x${Number(421613).toString(16)}`,  //421613
      chainName: "Arbitrum Goerli",
      nativeCurrency: {
        name: "AGOR",
        symbol: "AGOR",
        decimals: 18
      },
      rpcUrls: ["https://arbitrum-goerli.publicnode.com"],
      blockExplorerUrls: ["https://testnet.arbiscan.io/"]
    },
    evmos: {
      chainId: `0x${Number(9000).toString(16)}`,
      chainName: "Evmos Testnet",
      nativeCurrency: {
        name: "testEVMOS",
        symbol: "tEVMOS",
        decimals: 18
      },
      rpcUrls: ["https://eth.bd.evmos.dev:8545"], //https://eth.bd.evmos.dev:8545
      blockExplorerUrls: ["https://evm.evmos.dev/"]
  },
  binanceSmartChain: {
      chainId: `0x${Number(97).toString(16)}`,
      chainName: "Binance Smart Chain Testnet",
      nativeCurrency: {
        name: "Binance Chain Native Token",
        symbol: "tBNB",
        decimals: 18
      },
      rpcUrls: [
        "https://bsc-testnet.publicnode.com",
      ],
      blockExplorerUrls: ["https://testnet.bscscan.com/"]
    },
    fantom: {
      chainId: `0x${Number(4002).toString(16)}`,
      chainName: "Fantom Testnet",
      nativeCurrency: {
        name: "Fantom",
        symbol: "FTM",
        decimals: 18
      },
      rpcUrls: [
        "https://fantom-testnet.publicnode.com",
      ],
      blockExplorerUrls: ["https://testnet.ftmscan.com/"]
    },
    base: {
      chainId: `0x${Number(8453).toString(16)}`,
      chainName: "Base",
      nativeCurrency: {
        name: "Base",
        symbol: "ETH",
        decimals: 18
      },
      rpcUrls: [
        "https://base.llamarpc.com",
      ],
      blockExplorerUrls: ["https://basescan.org/"]
    }
  };