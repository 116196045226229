import React from 'react';
import { useParams } from 'react-router-dom';

import Layout from "../components/Layout";
import TradeArea from "../components/TradeArea";
import Footer from "../components/Footer";

// Refactor Home to a functional component
function Home() {
  // Now you can directly use hooks
  const { address } = useParams();

  return (
    <div>
      <Layout>
        <TradeArea address={address} />
        <Footer />
      </Layout>
    </div>
  );
}

export default Home;