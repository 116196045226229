import React from "react";
import Box from '@mui/material/Box';

export default function Layout({ children }) {

  return (
    <>
      <Box sx={{pl: "15px", pr: "15px", width: "100%"}}>
        {children}
      </Box>
    </>
  );

}