import React from 'react';

// mui styling imports
import { Typography, Container } from '@mui/material';
import TwitterIcon from '@mui/icons-material/Twitter';
import TelegramIcon from '@mui/icons-material/Telegram';

const footerStyle = {
  padding: '20px',
  position: 'sticky',
  bottom: 0,
  left: 0,
  width: '100%',
  marginTop: '250px',
};

const iconStyle = {
  fontSize: '21px',
  marginRight: '10px',
  cursor: 'pointer',
  '&:hover': {
    color: '#32CD32',
  },
};

const Footer = () => {
  const handleTwitterClick = () => {
    window.location.href = 'https://www.google.com/';
  };

  const handleTelegramClick = () => {
    window.location.href = 'https://www.google.com/';
  };

  return (
    <footer style={footerStyle}>
      <Container maxWidth="sm">
        <Typography variant="body1" align="center">
          {/* <TwitterIcon sx={iconStyle} onClick={handleTwitterClick} />
          <TelegramIcon sx={iconStyle} onClick={handleTelegramClick} /> */}
        </Typography>
      </Container>
    </footer>
  );
};

export default Footer;