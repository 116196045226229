import React, { useState, useEffect } from "react";

import { ethers } from "ethers";
import { networks } from "../lib/networks";

// import db API
import { uploadToken, uploadTransaction } from "../components/services/api"

// static ABI constants
import {
  BondingCurveTokenFactoryABI,
  BondingCurveTokenABI,
  MockWETHABI,
} from "../lib/constants";

export const ProtocolContext = React.createContext();

let eth;

if (typeof window !== "undefined") {
  eth = window.ethereum;
}

const changeNetwork = async ({ networkName, setError }) => {
  try {
    if (!window.ethereum) throw new Error("MetaMask Not found - Please install metamask");
    await window.ethereum.request({
      method: "wallet_addEthereumChain",
      params: [
        {
          ...networks[networkName]
        }
      ]
    });
  } catch (err) {
    setError(err.message);
  }
};

// Contract Interaction Code
export const ProtocolProvider = ({ children }) => {

// network change
const [error, setError] = useState();

const handleNetworkSwitch = async (networkName) => {
  setError();
  await changeNetwork({ networkName, setError });
};

const networkChanged = (chainId) => {
  console.log({ chainId });
};

const [pleaseUseMetamask, setPleaseUseMetamask] = useState(false);

// Chain select handling
const [open, setOpen] = useState(false); // modal set
const handleOpen = () => setOpen(true); // send modal
const handleClose = () => setOpen(false);// send modal close
const [openChains, setOpenChains] = useState(false); // chain list modal
const handleCloseChainlist = () => setOpenChains(false);// handle close for chain list
const handleOpenChainlist = () => setOpenChains(true);// handle close for chain list
const [selectedChain, setSelectedChain] = useState();
const [currentChainNumber, setCurrentChainNumber] = useState();

// contract loading
const [contractLoading , setContractLoading] = useState(false);
const [contractLoaded, setContractLoaded] = useState(false);

// account connected to DApp
const [currentAccount, setCurrentAccount] = useState(); // current account
const [selectedAddress, setSelectedAddress] = useState(""); // selected wallet to unlock

// generate tokens
const [lockContractAddress, setLockContractAddress] = useState(""); // TOKEN ADDRESS
const [deployedTokenAddresses, setDeployedTokenAddresses] = useState([]);

// network switch handling
useEffect(() => {
  if (window.ethereum) window.ethereum.on("chainChanged", networkChanged);
  return () => {
    if (window.ethereum) window.ethereum.removeListener("chainChanged", networkChanged);
  };
}, []);

// check metamask is connected
useEffect(() => {
  checkIfWalletIsConnected();
  if (window.ethereum) setCurrentChainNumber(window.ethereum.networkVersion);
}, []);

// listen for network changes
useEffect(() => {
  if (window.ethereum) window.ethereum.on("chainChanged", networkChanged);
  return () => {
    if (window.ethereum) window.ethereum.removeListener("chainChanged", networkChanged);
  };
}, []);

// listen for account changes
useEffect(() => {
  if (window.ethereum) window.ethereum.on("accountsChanged", async(accounts) => {
    checkIfWalletIsConnected()
    if (window.ethereum) setCurrentChainNumber(window.ethereum.networkVersion);
    // console.log("CURRENT CHAIN NUMBER", currentChainNumber);
  })
}, [])

/*
// change network
useEffect(() => {
  if(selectedChain != null){
  handleNetworkSwitch(selectedChain.name);
  setCurrentChainNumber(window.ethereum.networkVersion);
  }
}, [selectedChain])
*/

useEffect(() => {
  const addCustomChain = async () => {
    try {
      await window.ethereum.request({
        method: 'wallet_addEthereumChain',
        params: [{
          chainId: networks.selectedChain.name.chainId,
          rpcUrl: networks.selectedChain.name.rpcUrls[0],
          chainName: networks.selectedChain.name.chainName,
          nativeCurrency: {
            name: 'Custom ETH',
            symbol: 'cETH',
            decimals: 18,
          },
          blockExplorerUrls: networks.selectedChain.name.blockExplorerUrls[0]
        }]
      });
    } catch (error) {
      console.error('Error adding custom chain:', error);
    }
  };
  addCustomChain();
}, [selectedChain]);

// Supported chains
const chainNamesAll = [
  { name: 'fantom (testnet)', img: 'ftm', address: '0x66Aa33A365a45310cA4eFD899dCDC4eBa4C6b194'}, // 0x3d98dd781bDdBfB7E027D9EC02590e14B4700DD8 - OLD  '0x4D682E1F9E0190ED5107807cdd409bFaca5d252E'
  { name: 'base', img: 'base', address: '0xEf087dd9C2ebab6902E4C3b05E4bB4c50faCdB75'},
]

// check wallet connect
const checkIfWalletIsConnected = async (metamask = eth) => {
  try {
    if (!metamask) return alert("Please install MetaMask");
    const accounts = await metamask.request({ method: "eth_accounts" });
    if (accounts.length) {
      setCurrentAccount(accounts[0]);
      setCurrentChainNumber(window.ethereum.networkVersion);
      // Set connected status to true in local storage
      localStorage.setItem("walletConnected", "true");
    } else {
      setCurrentAccount(null);
      // If no accounts are found, ensure we clear the local storage
      localStorage.setItem("walletConnected", "false");
    }
  } catch (error) {
    console.error(error);
    localStorage.setItem("walletConnected", "false");
    throw new Error("No Ethereum object");
  }
};

// passing gen form variables
const [genFormParams: GenFormParams, setGenFormParams] = useState({
  name_: "",
  symbol_: "",
  slope_: "6000000000000", // 1 trill supply slope
  ipfsHash_: "",
  description_: "",
  website_: "",
  twitter_: "",
  telegram_: "",
  liquidity_: "",
});

// main smart contract factory - creating tokens
const getEthereumContractTokenFactory = () => {
  const provider = new ethers.providers.Web3Provider(eth);
  const signer = provider.getSigner();
  const lockInstance = new ethers.Contract(
    selectedChain.address,
    BondingCurveTokenFactoryABI,
    signer
  );
  return lockInstance;
};

// user created erc20 tokens
const getEthereumContractUserToken = (tokenAddress) => {
  const provider = new ethers.providers.Web3Provider(eth);
  const signer = provider.getSigner();
  const userTokenInstance = new ethers.Contract(
    tokenAddress,
    BondingCurveTokenABI,
    signer
  );
  return userTokenInstance;
};

// Wrapped Ether (TEST)
const getEthereumContractWrappedEther = () => {
  const provider = new ethers.providers.Web3Provider(eth);
  const signer = provider.getSigner();
  const userWethInstance = new ethers.Contract(
    '0x4d4a744CcFc5292F036c175BDB025405a28400fF', // FTM TEST
    MockWETHABI,
    signer
  );
  return userWethInstance;
};

// connect wallet
const connectWallet = async (metamask = eth) => {
  try {
    if (!metamask) {
      setPleaseUseMetamask(true);
      return;
    }
    const accounts = await metamask.request({
      method: "eth_requestAccounts",
    });
    setCurrentAccount(accounts[0]);
    setPleaseUseMetamask(false);
  } catch (error) {
    console.error(error);
    throw new Error("Failed to connect or no Ethereum object available.");
  }
};

// https://scarlet-rapid-antelope-514.mypinata.cloud/ipfs/QmRc2oPEKxC5daVARa89zpK9KEwLwFP1SBKr9qJaC8qk5P
// generate a new token
// https://testnet.ftmscan.com/token/0x4d4a744CcFc5292F036c175BDB025405a28400fF#writeContract   | APPROVE WETH HERE
const generateToken = async () => {
  const {name_, symbol_, slope_, ipfsHash_, description_, website_, twitter_, telegram_, liquidity_} = genFormParams;
  const tokenCreateInstance = getEthereumContractTokenFactory();
  const wethCreateInstance = getEthereumContractWrappedEther();
  const firstLiq = liquidity_; // pre declare liq variable
  const MAX_UINT256 = ethers.constants.MaxUint256;
  try {
    (async () => {                                                                 
    setContractLoading(true);
    const finalLiq = firstLiq + '000000000000000000'; // pad zeros for wei
    const directApproval_ = false;
    // Swap ETH to WETH
    // const tx1 = await wethContract.deposit({ value: ethAmount });  ADD IN PRODUCTION MAIN NET!
    // approve weth
    const approve = await wethCreateInstance.approve('0x66Aa33A365a45310cA4eFD899dCDC4eBa4C6b194', MAX_UINT256);
    let approval = await approve.wait();
    // generate erc20 token
    const generateResult = await tokenCreateInstance.createToken(
      name_,
      symbol_,
      slope_,
      ipfsHash_,
      description_,
      finalLiq, // custom erc20 tokens in wei scale format
      directApproval_,
    );
    let tx = await generateResult.wait();
    let address_ = tx.logs[0].address.toString();
    setContractLoading(false);
    setContractLoaded(true);
    setLockContractAddress(address_);
    // console.log("the ipfs hash line 255 generateTokenContext:", ipfsHash_);
    uploadToken(address_, name_, symbol_, ipfsHash_, description_, website_, twitter_, telegram_, finalLiq);
    })();
  } catch (error) {
    console.log(error);
  };
};

const purchaseToken = async (tokenAddress, amount) => {
  const bondingCurveToken = getEthereumContractUserToken(tokenAddress); // must approve weth currently
  const wethCreateInstance = getEthereumContractWrappedEther();
  const MAX_UINT256 = ethers.constants.MaxUint256;
  try {
      const approve = await wethCreateInstance.approve(tokenAddress, MAX_UINT256);
      let approval = await approve.wait();
      const tokenAmount = ethers.utils.parseUnits(amount, 18);

      const purchaseTx = await bondingCurveToken.Buy(tokenAmount);
      const receipt = await purchaseTx.wait();
      const transactionEvent = receipt.logs[0].transactionHash.toString();

      // update price
      const currentPrice = await bondingCurveToken.GetCurrentPrice();
      const number = parseInt(currentPrice, 16);
      
      uploadTransaction( tokenAddress, '1', currentAccount, amount, number, transactionEvent)
  } catch (error) {
      console.error("Error purchasing tokens:", error);
  }
};

// SELL TOKENS
const sellToken = async(tokenAddress, amount) => {
  const bondingCurveToken = getEthereumContractUserToken(tokenAddress);
  const wethCreateInstance = getEthereumContractWrappedEther();
  const MAX_UINT256 = ethers.constants.MaxUint256;
  try {

    // const tokenAmount = ethers.utils.parseUnits(amount, 18);
    // console.log("Token AMOUNT ___", tokenAmount);

    const sellTx = await bondingCurveToken.Sell(amount); //tokenAmount   WTF it doesnt allow large amounts only like 0.0001 MAX sell
    const receipt = await sellTx.wait();
    const transactionEvent = receipt.logs[0].transactionHash.toString();

    // update price
    const currentPrice = await bondingCurveToken.GetCurrentPrice();
    const number = parseInt(currentPrice, 16);

    uploadTransaction(tokenAddress, '0', currentAccount, amount, number, transactionEvent)
    console.log("Transaction confirmed! receipt", receipt);
  } catch (error) {
    console.log("Error fetching tokens:", error);
  }
}


// GET TOKEN PRICE DATA
const currentTokenPrice = async(tokenAddress, amount) => {
  const bondingCurveToken = getEthereumContractUserToken(tokenAddress);
  try {
    const tokenAmount = amount + '000000000000000000' // convert to wei pad zeros
    const getPrice = await bondingCurveToken.GetCurrentPrice();
    // getPrice
    const price = parseInt(getPrice, 16);
    // calculate price
    const priceInWei = ((tokenAmount * price)/1000000000000000000)
    const finalPrice = (priceInWei/100000000000000000000)
    return finalPrice;
  } catch (error) {
    console.log("Error fetching tokens:", error);
  }
}

// GET TOKEN SUPPLY DATA
const currentTokenSupply = async(tokenAddress) => {
  const bondingCurveToken = getEthereumContractUserToken(tokenAddress);
  try {

    const getSupply = await bondingCurveToken.totalSupply();

    // Convert Wei to Ether
    const etherValue = ethers.utils.formatUnits(getSupply, 'wei');

    // Convert Ether value to a BigNumber
    const etherBigNumber = ethers.BigNumber.from(etherValue);

    // Divide by 1 trillion
    const dividedValue = etherBigNumber.div(1e12); // 1e12 represents 1 trillion

    // Multiply by 100 to get the percentage
    const percentageValue = dividedValue.mul(100);
    const decimalPercentage = ethers.utils.formatUnits(percentageValue, 'ether');
    const truncatedPercentage = decimalPercentage.split('.')[0];

    // console.log(" the precccccccccccentrage valueeeeeeeeeeeeeeeeeeeeeeee: ", truncatedPercentage)

    return truncatedPercentage;

  } catch (error) {
    console.log("Error fetching tokens:", error);
  }
}


  return (
    <ProtocolContext.Provider
      value = {{
        currentAccount,
        connectWallet,
        setSelectedAddress,
        selectedAddress,
        handleOpen,
        handleClose,
        open,
        handleCloseChainlist,
        handleOpenChainlist,
        openChains,
        selectedChain,
        setSelectedChain,
        chainNamesAll,
        currentChainNumber,
        generateToken,
        genFormParams,
        setGenFormParams,
        contractLoading,
        contractLoaded,
        lockContractAddress,
        deployedTokenAddresses,
        purchaseToken,
        sellToken,
        currentTokenPrice,
        currentTokenSupply
        
      }}
      >
        {children}
    </ProtocolContext.Provider>
  )
}