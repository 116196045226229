import React from "react";

// styling imports
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";

// import components
import LockManager from "./LockManager.js";

const ManageArea = () => {

  return (
    <Box
      sx={{
        width: "100%",
        pr: "12px",
        pl: "10px",
      }}
    >
      
    <Paper elevation={1} sx={{ height: 530, borderRadius: '16px' }}>
        <Box sx={{ pl: "20px", pr: "20px", pt: "20px", pb: "20px" }}>
            <LockManager />
        </Box>
    </Paper>
    </Box>
  );
};

export default ManageArea;