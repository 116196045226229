import React, { useContext } from "react";
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { FixedSizeList } from 'react-window';

// chain images
import Evmos from '../../assets/images/Evmos_Chain.svg'
import Pulse from '../../assets/images/pulse.png'
import eth from '../../assets/images/eth.png'
import base from '../../assets/images/base.svg'
import ftm from '../../assets/images/ftm.svg'
import arb from '../../assets/images/arbitrum.png'

// import context
import { ProtocolContext } from '../../context/ProtocolContext.js';

export default function ChainListFull() {

  // import from context | first symbol determines the symbol in the modal
  const { setSelectedChain, handleCloseChainlist, chainNamesAll} = useContext(ProtocolContext);

  const logosModalEnums = {

    ftm: <img style={{ width: 25, height: 25 }} src={ftm} alt='ftm'/>,
    base: <img style={{ width: 25, height: 25 }} src={base} alt='base'/>

  };

  function renderRow(props) {
    const { index, style } = props;
  
    return (
      <ListItem style={style} key={index}>
              <ListItemButton
        onClick={() => {
          setSelectedChain(chainNamesAll[index]);
          handleCloseChainlist();
        }}
        style={{
          borderRadius: '8px',
        }}
      >
        <ListItemText
          primary={
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {logosModalEnums[chainNamesAll[index].img]}
              <Box sx={{ pr: '20px' }}></Box>
              {chainNamesAll[index].name}
            </div>
          }
        />
      </ListItemButton>
      </ListItem>
    );
  }

  return (
    // *must match the list quantity in context or else blank screen (eg. itemCount = 5, list 5 chains)*
      <FixedSizeList
      height={395}
      width={'100%'}
      itemSize={46}
      itemCount={2}
      overscanCount={2}
      >
        {renderRow}
      </FixedSizeList>
  );
}