import { createTheme } from '@mui/material/styles';

const primaryColor = "#8b5cf6"
const secondaryColor = "#021626"

const theme = createTheme({
  palette: {
    mode: "dark",
    
    primary: {
      main: primaryColor,
    },
    secondary: {
      main: secondaryColor,
    },
  },

  components: {
    MuiButton: {
    styleOverrides: {
      text: {
        textTransform: 'none',
        '&:hover' :{
          color: primaryColor,
        }
      },
    },
  },
},
});



export default theme;